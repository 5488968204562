import type { IconRootProps } from './Root'
import { IconRoot } from './Root'

export const ArrowDownIcon = ({ size = '1.5rem', ...props }: IconRootProps) => (
  <IconRoot
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    size={size}
    {...props}
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.03033 11.4698C5.73744 11.1769 5.26256 11.1769 4.96967 11.4698C4.67678 11.7627 4.67678 12.2376 4.96967 12.5305L10.7626 18.3234C11.446 19.0068 12.554 19.0068 13.2374 18.3234L19.0303 12.5305C19.3232 12.2376 19.3232 11.7627 19.0303 11.4698C18.7374 11.1769 18.2626 11.1769 17.9697 11.4698L12.75 16.6895L12.75 5.00012C12.75 4.58591 12.4142 4.25012 12 4.25012C11.5858 4.25012 11.25 4.58591 11.25 5.00012L11.25 16.6895L6.03033 11.4698Z"
    />
  </IconRoot>
)
