import { useTranslation } from 'next-i18next'
import { Button } from 'ui/src/components/Button/Button'
import { Dialog } from 'ui/src/components/Dialog/Dialog'
import { FullscreenDialog } from 'ui/src/components/Dialog/FullscreenDialog'
import { WarningTriangleIcon } from 'ui/src/icons/WarningTriangleIcon'
import { Text, theme } from 'ui'
import * as GridLayout from '@/components/GridLayout/GridLayout'
import { SpaceFlex } from '@/components/SpaceFlex/SpaceFlex'

type Props = {
  errorMessage?: string
  open: boolean
  onOpenChange: () => void
  onEditClick: () => void
}
export const PurchaseFormErrorDialog = (props: Props) => {
  const { t } = useTranslation('purchase-form')
  return (
    <Dialog.Root open={props.open} onOpenChange={props.onOpenChange}>
      <FullscreenDialog.Modal
        center={true}
        Footer={
          <>
            <Button type="button" onClick={props.onEditClick} fullWidth={true}>
              {t('GENERAL_ERROR_DIALOG_PRIMARY_BUTTON')}
            </Button>
            <Dialog.Close asChild={true}>
              <Button type="button" variant="ghost" fullWidth={true}>
                {t('DIALOG_BUTTON_CANCEL', { ns: 'common' })}
              </Button>
            </Dialog.Close>
          </>
        }
      >
        <GridLayout.Root>
          <GridLayout.Content width="1/3" align="center">
            <SpaceFlex direction="vertical" align="center" space={0}>
              <Text size={{ _: 'lg', lg: 'xl' }}>
                <SpaceFlex space={0.25} align="center">
                  <WarningTriangleIcon size="1em" color={theme.colors.signalAmberElement} />
                  {t('GENERAL_ERROR_DIALOG_TITLE', { ns: 'common' })}
                </SpaceFlex>
              </Text>
              <Text
                size={{ _: 'lg', lg: 'xl' }}
                align="center"
                color="textSecondary"
                balance={true}
              >
                {props.errorMessage ? props.errorMessage : t('GENERAL_ERROR_DIALOG_PROMPT')}
              </Text>
            </SpaceFlex>
          </GridLayout.Content>
        </GridLayout.Root>
      </FullscreenDialog.Modal>
    </Dialog.Root>
  )
}
