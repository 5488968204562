'use client'
import { createContext, type ReactNode, useContext } from 'react'
import { type TierComparison } from './fetchTierComparison'

const TierComparisonContext = createContext<TierComparison | null>(null)

type Props = { children: ReactNode; tierComparison: TierComparison | null }

export function TierComparisonProvider({ children, tierComparison }: Props) {
  return (
    <TierComparisonContext.Provider value={tierComparison}>
      {children}
    </TierComparisonContext.Provider>
  )
}

export function useTierComparison(): TierComparison {
  const value = useContext(TierComparisonContext)
  if (value == null) {
    throw new Error('No tierComparison data')
  }
  return value
}
