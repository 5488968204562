import { type TierComparison } from '@/appComponents/TierComparison/fetchTierComparison'
import { type Body, TableMarkers } from './ComparisonTable.types'

// Generic comparison table is used for other things than tier coverage, so it has more abstract data structure
export function tierComparisonToTable(
  tierComparison: TierComparison,
  options?: { selectedTermsVersion?: string; showCoverageText?: boolean },
) {
  let selectedTierDisplayName
  if (options?.selectedTermsVersion) {
    selectedTierDisplayName =
      tierComparison.variantColumns.find((col) => col.termsVersion === options.selectedTermsVersion)
        ?.displayNameTier ?? undefined
  }

  const head = [
    TableMarkers.EmptyHeader,
    ...tierComparison.variantColumns.map((col) => col.displayNameTier!),
  ]
  const body: Body = tierComparison.rows.map((row) => [
    { title: row.title, description: row.description },
    ...row.cells.map((cell) => {
      if (cell.coverageText && options?.showCoverageText) {
        return cell.coverageText
      } else if (cell.isCovered) {
        return TableMarkers.Covered
      } else {
        return TableMarkers.NotCovered
      }
    }),
  ])
  return { table: { head, body }, selectedTierDisplayName }
}
