'use client'
import { storyblokEditable } from '@storyblok/react'
import { useTierComparison } from '@/appComponents/TierComparison/TierComparisonProvider'
import { DesktopComparisonTable } from '@/components/ComparisonTable/DesktopComparisonTable'
import { MobileComparisonTable } from '@/components/ComparisonTable/MobileComparisonTable'
import { tierComparisonToTable } from '@/components/ComparisonTable/tierComparisonToTable'
import type { SbBaseBlockProps } from '@/services/storyblok/storyblok'
import { useResponsiveVariant } from '@/utils/useResponsiveVariant'

type Props = SbBaseBlockProps<unknown>
export function ProductTierComparisonBlock({ blok }: Props) {
  const variant = useResponsiveVariant('sm')
  const tierComparison = useTierComparison()
  const { table } = tierComparisonToTable(tierComparison, {
    showCoverageText: true,
  })
  return (
    <div {...storyblokEditable(blok)}>
      {variant === 'mobile' && <MobileComparisonTable {...table} />}
      {variant === 'desktop' && <DesktopComparisonTable {...table} />}
    </div>
  )
}
