'use client'
import type { SbBlokData } from '@storyblok/react'
import { StoryblokComponent, storyblokEditable } from '@storyblok/react'
import type { ComponentProps, ReactNode } from 'react'
import { Button } from 'ui/src/components/Button/Button'
import { Dialog } from 'ui/src/components/Dialog/Dialog'
import { FullscreenDialog } from 'ui/src/components/Dialog/FullscreenDialog'
import { LargeDialog } from 'ui/src/components/Dialog/LargeDialog'
import { sprinkles } from 'ui/src/theme/sprinkles.css'
import { visuallyHidden } from 'ui/src/utils/visuallyHidden.css'
import type { SbBaseBlockProps } from '@/services/storyblok/storyblok'

export type ModalBlockProps = SbBaseBlockProps<{
  buttonText: string
  buttonVariant?: ComponentProps<typeof Button>['variant']
  buttonSize?: ComponentProps<typeof Button>['size']
  modalContent: Array<SbBlokData>
  useLegacyFullscreenDialog?: boolean
}>

export const ModalBlock = ({ blok }: ModalBlockProps) => {
  let dialogContent: ReactNode
  if (blok.useLegacyFullscreenDialog || blok.useLegacyFullscreenDialog == null) {
    dialogContent = (
      <FullscreenDialog.Modal>
        <Dialog.Title className={visuallyHidden} />
        {blok.modalContent.map((nestedBlock) => (
          <StoryblokComponent key={nestedBlock._uid} blok={nestedBlock} nested={true} />
        ))}
      </FullscreenDialog.Modal>
    )
  } else {
    dialogContent = (
      <LargeDialog.Content closeOnPointerDownOutside={true}>
        <Dialog.Title className={visuallyHidden} />
        <LargeDialog.ScrollableInnerContent>
          {blok.modalContent.map((nestedBlock) => (
            <StoryblokComponent key={nestedBlock._uid} blok={nestedBlock} nested={true} />
          ))}
        </LargeDialog.ScrollableInnerContent>
      </LargeDialog.Content>
    )
  }
  return (
    <div
      {...storyblokEditable(blok)}
      className={sprinkles({ display: 'flex', justifyContent: 'center', paddingInline: 'md' })}
    >
      <Dialog.Root>
        <Dialog.Trigger asChild>
          <Button variant={blok.buttonVariant ?? 'primary'} size={blok.buttonSize ?? 'medium'}>
            {blok.buttonText}
          </Button>
        </Dialog.Trigger>
        {dialogContent}
      </Dialog.Root>
    </div>
  )
}
