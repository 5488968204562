import { clsx } from 'clsx'
import { motion } from 'framer-motion'
import { type ComponentProps } from 'react'
import { CrossIcon } from '../../icons/CrossIcon'
import { framerTransitions } from '../../theme'
import { IconButton } from '../Button/IconButton'
import { Card } from '../Card/Card'
import { Heading } from '../Heading/Heading'
import { Dialog } from './Dialog'
import {
  closeButton,
  content,
  heading,
  scrollableInnerContent,
  CLOSE_BUTTON_SIZE,
} from './LargeDialog.css'

const MotionCard = motion(Card.Root)

// Large modal, full-screen on mobile. Uses `Root` and `Trigger` from default `Dialog`
function Content({
  children,
  centerContent = true,
  className,
  frostedOverlay = true,
  ...forwardedProps
}: ComponentProps<typeof Dialog.Content>) {
  return (
    <Dialog.Content
      centerContent={centerContent}
      frostedOverlay={frostedOverlay}
      asChild={true}
      {...forwardedProps}
    >
      <MotionCard
        className={clsx(content, className)}
        initial={{ opacity: 0, y: '2vh' }}
        animate={{ opacity: 1, y: 0 }}
        transition={{
          ...framerTransitions.easeInOutCubic,
          duration: framerTransitions.defaultDuration,
        }}
      >
        <Dialog.Close asChild>
          <IconButton
            variant="secondary"
            Icon={<CrossIcon size={CLOSE_BUTTON_SIZE} />}
            className={closeButton}
          />
        </Dialog.Close>
        {children}
      </MotionCard>
    </Dialog.Content>
  )
}

function Header({ children, className, ...forwardedProps }: ComponentProps<typeof Heading>) {
  return (
    <Dialog.Title asChild>
      <Heading
        as="h2"
        variant={{ _: 'standard.18', md: 'standard.24' }}
        className={clsx(heading, className)}
        {...forwardedProps}
      >
        {children}
      </Heading>
    </Dialog.Title>
  )
}

function ScrollableInnerContent({ children, className, ...forwardedProps }: ComponentProps<'div'>) {
  return (
    <div className={clsx(scrollableInnerContent, className)} {...forwardedProps}>
      {children}
    </div>
  )
}

export const LargeDialog = {
  Content,
  Header,
  ScrollableInnerContent,
}
